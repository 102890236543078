<template>
  <navbar></navbar>

  <div class="main-container" v-if="loggedInUser != null">
    <div class="row">
      <!-- Menu for Settings -->
      <div class="col-md-2 col-sm-12 clearfix mb-2 mb-md-0">
        <h3>Settings</h3>
        <div class="settings-menu clearfix mb-1 mb-md-0">
          <div class="d-inline-block d-md-block mr-2 mr-md-0 settings-menu-item">
            <a :class="{'active': activeTab === 'timeslot'}"
               href="javascript:" @click="activeTab = 'timeslot'">
              <i class="fad fa-fw fa-bells"></i>
              Reminder Slots
            </a>
          </div>
          <div class="d-inline-block d-md-block mr-2 mr-md-0 settings-menu-item">
            <a :class="{'active': activeTab === 'journals'}"
                href="javascript:" @click="activeTab = 'journals'">
              <i class="fad fa-fw fa-books"></i>
              Journals
            </a>
          </div>
          <div class="d-inline-block d-md-block mr-md-0 settings-menu-item">
            <a :class="{'active': activeTab === 'telegram'}"
                href="javascript:" @click="activeTab = 'telegram'">
              <i class="fab fa-fw fa-telegram-plane"></i>
              Telegram Settings &nbsp;
              <i class="fad fa-fw fa-unlink d-md-inline d-none"
                 v-if="!loggedInUser.telegram_setup"></i>
              <i class="fad fa-fw fa-link d-md-inline d-none"
                 v-if="loggedInUser.telegram_setup"></i>
            </a>
          </div>
        </div>
      </div>

      <!-- Settings UI -->
      <div class="col-md-10 col-sm-12">
        <!-- Timeslot -->
        <div v-show="activeTab === 'timeslot'">
          <!-- Timezone -->
          <div class="light-gray-rounded-box">
            <div class="row">
              <div class="col-12">
                <strong>Select your timezone</strong>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12 margin-top-element">
                <select v-model="timeZone" class="form-control">
                  <option></option>
                  <option value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
                  <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                  <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                  <option value="US/Alaska">(GMT-09:00) Alaska</option>
                  <option value="America/Los_Angeles">
                    (GMT-08:00) Pacific Time (US & Canada)</option>
                  <option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
                  <option value="US/Arizona">(GMT-07:00) Arizona</option>
                  <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                  <option value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</option>
                  <option value="America/Managua">(GMT-06:00) Central America</option>
                  <option value="US/Central">(GMT-06:00) Central Time (US & Canada)</option>
                  <option value="America/Mexico_City">
                    (GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                  <option value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</option>
                  <option value="America/Bogota">
                    (GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                  <option value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</option>
                  <option value="US/East-Indiana">(GMT-05:00) Indiana (East)</option>
                  <option value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</option>
                  <option value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
                  <option value="America/Manaus">(GMT-04:00) Manaus</option>
                  <option value="America/Santiago">(GMT-04:00) Santiago</option>
                  <option value="Canada/Newfoundland">(GMT-03:30) Newfoundland</option>
                  <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                  <option value="America/Argentina/Buenos_Aires">
                    (GMT-03:00) Buenos Aires, Georgetown</option>
                  <option value="America/Godthab">(GMT-03:00) Greenland</option>
                  <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                  <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                  <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                  <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                  <option value="Africa/Casablanca">
                    (GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
                  <option value="Etc/Greenwich">
                    (GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
                  <option value="Europe/Amsterdam">
                    (GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                  <option value="Europe/Belgrade">
                    (GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                  <option value="Europe/Brussels">
                    (GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                  <option value="Europe/Sarajevo">
                    (GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                  <option value="Africa/Lagos">(GMT+01:00) West Central Africa</option>
                  <option value="Asia/Amman">(GMT+02:00) Amman</option>
                  <option value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                  <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                  <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                  <option value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
                  <option value="Europe/Helsinki">
                    (GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                  <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                  <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                  <option value="Africa/Windhoek">(GMT+02:00) Windhoek</option>
                  <option value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
                  <option value="Europe/Moscow">
                    (GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                  <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
                  <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                  <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                  <option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
                  <option value="Asia/Baku">(GMT+04:00) Baku</option>
                  <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                  <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                  <option value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
                  <option value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
                  <option value="Asia/Calcutta">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi,
                    Sri Jayawardenapura</option>
                  <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                  <option value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
                  <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                  <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                  <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                  <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                  <option value="Asia/Hong_Kong">
                    (GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                  <option value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
                  <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                  <option value="Australia/Perth">(GMT+08:00) Perth</option>
                  <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
                  <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                  <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                  <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                  <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                  <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                  <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                  <option value="Australia/Canberra">
                    (GMT+10:00) Canberra, Melbourne, Sydney</option>
                  <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                  <option value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
                  <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                  <option value="Asia/Magadan">
                    (GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                  <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                  <option value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                  <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                </select>
              </div>
              <div class="col-md-6 col-sm-12 margin-top-element">
                <button @click="saveTimezone();" class="btn btn-primary wide-on-mobile">
                  Save Timezone</button>
                <loader v-show="loading.timezone"></loader>
              </div>
            </div>
          </div>

          <!-- Add reminder slots -->
          <div class="light-gray-rounded-box margin-top-section">
            <div class="row">
              <div class="col-12">
                <strong>Add slots for email reminders</strong>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-12 margin-top-element">
                <select v-model="dayOfWeek" class="form-control">
                  <option value="1">Monday</option>
                  <option value="2">Tuesday</option>
                  <option value="3">Wednesday</option>
                  <option value="4">Thursday</option>
                  <option value="5">Friday</option>
                  <option value="6">Saturday</option>
                  <option value="0">Sunday</option>
                </select>
              </div>
              <div class="col-md-3 col-sm-12 margin-top-element">
                <select v-model="timeOfDay" class="form-control">
                  <option v-for="ts in allTimes" v-bind:key="ts" :value="ts">{{ts}}</option>
                </select>
              </div>
              <div class="col-md-6 col-sm-12 margin-top-element">
                <button :disabled="!loggedInUser.email_verified"
                        @click="saveSchedule();" class="btn btn-primary wide-on-mobile">
                  Add Slot</button>
                <loader v-show="loading.schedule"></loader>
              </div>
            </div>
            <div class="row" v-if="!loggedInUser.email_verified">
              <div class="col-md-6 col-sm-12 mt-3">
                <div class="alert alert-warning clearfix">
                  <div class="float-left">
                    <i class="fa fa-exclamation-triangle"></i><br />
                  </div>
                  <div class="float-left pl-2" style="width: calc(100% - 32px)">
                    You can add reminders once your email is verified.<br />
                    <a v-if="!verificationEmailSent && !loading.verificationEmail"
                       @click="sendVerificationEmail()"
                       href="javascript:" class="small">
                      Send verification email again</a>
                    <span class="small" v-if="loading.verificationEmail">
                <i class="fas fa-spin fa-spinner-third"></i>&nbsp;
                Pushing your verification email through this universe to your inbox ...
              </span>
                    <span v-if="verificationEmailSent" class="small">You should have got an email
              with instructions on verifying your email (check your spam as well).</span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!-- Existing reminder time slots -->
          <div class="light-gray-rounded-box margin-top-section">
            <div class="row">
              <div class="col-12">
                <strong>Existing time slots</strong>
              </div>
            </div>

            <div id="time-slots-container">
              <div v-if="schedules && schedules.length > 0" class="time-slots">
                <schedule-row
                    v-for="schedule in schedules" v-bind:key="schedule.id"
                    :day_of_week="daysOfWeek[schedule.day_of_week]"
                    :time_of_email="schedule.time_of_email"
                    @schedule_deleted="recordDeletedSchedule"
                    :schedule_id="schedule.id"></schedule-row>
              </div>

              <div v-if="!loading.schedule && (schedules == null || schedules.length === 0)"
                   class="time-slots">
                <div class="row time-slot">
                  <div class="col-12">
                    No timeslots have been added so far.
                    No email reminders will be sent. Create a slot now.
                  </div>
                </div>
              </div>

              <div v-if="loading.schedule">
                <div class="row time-slot">
                  <div class="col-12">
                    <i class="fas fa-spinner-third fa-spin"></i>&nbsp;
                    Loading schedules...
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

        <!-- Journal -->
        <div v-show="activeTab === 'journals'">
          <journal-settings v-if="loggedInUser" :user="loggedInUser"></journal-settings>
        </div>

        <!-- Telegram -->
        <div v-show="activeTab === 'telegram'">
          <!-- Telegram Settings -->
          <telegram-settings
              v-if="loggedInUser"
              @refresh_logged_in_user="refreshLoggedInUser"
              :user-email-reminder-flag="loggedInUser.remind_on_email"
              :user-telegram-reminder-flag="loggedInUser.remind_on_telegram"
              :is-telegram-setup="loggedInUser ? loggedInUser.telegram_setup : false"
              :user-email="loggedInUser ? loggedInUser.email : null"></telegram-settings>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import auth from '@/app/auth';
import Navbar from '@/components/Navbar.vue';
import utils from '@/app/utils';
import userInfo from '@/app/user-info';
import Loader from '@/components/Loader.vue';
import schedule from '@/app/schedule';
import ScheduleRow from '@/components/ScheduleRow.vue';
import TelegramSettings from '@/components/Setting/TelegramSettings.vue';
import JournalSettings from '@/components/Setting/JournalSettings.vue';

export default {
  components: {
    JournalSettings,
    TelegramSettings,
    ScheduleRow,
    Loader,
    Navbar,
  },
  computed: {
    allTimes() {
      return utils.getTimesInDay();
    },
  },
  data() {
    return {
      dayOfWeek: 1,
      timeOfDay: '6:00 PM',
      timeZone: null,
      originalTimeZone: null,
      loggedInUser: null,
      schedules: [],
      daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      loading: {
        timezone: false,
        schedule: false,
        verificationEmail: false,
      },
      verificationEmailSent: false,
      activeTab: 'timeslot',
    };
  },
  methods: {
    async fetchUserAndTimezoneDetails() {
      this.loading.timezone = true;
      try {
        const user = await userInfo.fetchUser(await auth.getUserIdToken());
        this.loggedInUser = user;
        this.loading.timezone = false;
        this.timeZone = user.time_zone;
        this.originalTimeZone = user.time_zone;
      } catch {
        utils.showToastError('Could not fetch your timezone',
          'An error occurred and I could not fetch your timezone information. '
            + 'Please refresh the page and try again.', true);
      }
    },
    async saveSchedule() {
      // Check if the timezone has been set. If not, a schedule cannot be created.
      if (this.loggedInUser && (!this.loggedInUser.time_zone || this.loggedInUser.time_zone === '')) {
        utils.showToastError('Please update your timezone.',
          'A schedule can be created only after a timezone has been updated. '
            + 'Please setup your timezone to continue.', true);
        return;
      }

      if (await this.checkScheduleAlreadyExists(this.dayOfWeek, this.timeOfDay)) {
      // Check if schedule already exists
        utils.showToastSuccess('Schedule already exists!',
          'You already have a schedule setup for this time. A new one will not be created.');
        return;
      }

      this.loading.schedule = true;
      try {
        await schedule.saveSchedule(await auth.getUserIdToken(), null, this.dayOfWeek,
          this.timeOfDay);
        await this.fetchSchedules();
        this.loading.schedule = false;
        utils.showToastSuccess('Great Success', 'The schedule was saved successfully.');
      } catch (err) {
        this.loading.schedule = false;
        utils.showToastError('Could not save schedule.',
          'An error occurred and I could not save your schedule. '
            + 'Please refresh the page and try again.', true);
      }
    },
    async fetchSchedules(allSchedules) {
      this.loading.schedule = true;
      try {
        if (allSchedules == null || allSchedules.length === 0) {
          this.schedules = await schedule.fetchAllSchedules(await auth.getUserIdToken());
        } else {
          this.schedules = _.clone(allSchedules);
        }

        this.schedules = _.orderBy(this.schedules, ['day_of_week'], ['asc']);
        this.loading.schedule = false;
      } catch {
        utils.showToastError('Could not fetch your schedules.',
          'An error occurred and I could not fetch your schedule information. '
            + 'Please refresh the page and try again.', true);
      }
    },
    async saveTimezone() {
      try {
        if (this.timeZone === '') {
          utils.showToastError('Invalid timezone.',
            'Please select a valid timezone to continue.', true);
          return;
        }
        this.loading.timezone = true;
        await userInfo.updateTimezone(await auth.getUserIdToken(), this.timeZone);
        this.loading.timezone = false;
        this.originalTimeZone = this.timeZone;
        utils.showToastSuccess('Great Success!', 'Your timezone information was updated '
            + 'successfully!');

        // Reload the logged in user details
        this.refreshLoggedInUser();
      } catch {
        this.loading.timezone = false;
        utils.showToastError('Could not save your timezone',
          'An error occurred and I could not save your timezone information. '
            + 'Please refresh the page and try again.', true);
        this.timeZone = this.originalTimeZone;
      }
    },
    recordDeletedSchedule(scheduleId) {
      // Remove it immediately from the UI
      this.schedules = _.filter(this.schedules, (x) => x.id !== scheduleId);

      // Fetch in the background
      this.fetchSchedules(null);
    },
    refreshLoggedInUser() {
      this.fetchUserAndTimezoneDetails();
    },
    async checkScheduleAlreadyExists(dayOfWeek, timeOfEmail) {
      const sch = _.find(this.schedules,
        { time_of_email: timeOfEmail, day_of_week: parseInt(dayOfWeek, 10) });

      return sch != null;
    },
    async sendVerificationEmail() {
      this.loading.verificationEmail = true;
      try {
        await userInfo.sendVerificationEmail(await auth.getUserIdToken());
        this.loading.verificationEmail = false;
        this.verificationEmailSent = true;
      } catch (error) {
        utils.showToastError('Could not send the email!',
          'An error occurred and we could not send the email across. Please refresh the'
            + ' page and try again.');
        this.loading.verificationEmail = false;
        this.verificationEmailSent = false;
      }
    },
  },
  mounted() {
    this.fetchUserAndTimezoneDetails();
    this.fetchSchedules(null);
  },
};
</script>
