<template>
  <!-- Telegram Settings -->
  <div class="light-gray-rounded-box">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <strong>Telegram Settings</strong>
      </div>
      <div class="col-md-6 col-sm-12 right aligned">
          <span v-if="!telegramConnected && telegramProcessing">
            <button @click="cancelTelegramConnection"
                    class="btn btn-primary btn-sm">Cancel</button>
          </span>
      </div>
    </div>

    <div class="row margin-top-element" v-if="!telegramConnected && !telegramProcessing">
      <div class="col-12">
        <div>
          <i class="fa fa-unlink fa-fw"></i>
          You are currently not connected to the Telegram Bot.
        </div>
        <button @click="startTelegramConnection();"
                class="btn btn-primary btn-sm margin-top-element">
          <i class="fab fa-telegram"></i>&nbsp;
          Connect to Telegram now</button><loader v-if="telegramRequestLoading"></loader>
      </div>
    </div>

    <!-- Processing -->
    <div v-if="!telegramConnected && telegramProcessing">
      <div class="row margin-top-element">
        <div class="col-md-6 col-sm-12" style="border-right: 1px solid #cdcdcd;">
          <strong>Option 1: Connect Automatically</strong>
          <p>
            If you are on a phone or computer which has the Telegram app installed, clicking
            the button below will connect your account to the Telegram bot.
          </p>
          <p>
            <a target="_blank" :href="this.telegramConnectionString" class="btn btn-primary">
              <i class="fab fa-telegram"></i>&nbsp;
              Open Telegram and Connect
            </a>
          </p>
        </div>

        <div class="col-md-6 col-sm-12">
          <strong>Option 2: Connect Manually</strong>
          <ol>
            <li>Open the Telegram app on your phone or computer.</li>
            <li>Search for <strong>"{{telegramBot.name}}"</strong> to locate the bot.</li>
            <li>Please confirm that the username of the bot is:
              <strong>@{{telegramBot.username}}</strong>.</li>
            <li>Send the following message to the bot to connect your account:</li>
          </ol>
          <div class="telegram-code">
            /connect {{telegram_codes.code}} {{userEmail}}</div>
        </div>
      </div>
      <div class="row margin-top-section">
        <div class="col-12 center aligned">
          <button @click="telegramStepsCompleted()" class="btn btn-primary">
            <i class="fa fa-check fa-fw"></i>&nbsp;
            I have completed these steps!
          </button>
        </div>
      </div>
      <div class="row margin-top-element margin-bottom-element" v-if="telegramStepsCompletedFlag">
        <div class="col-12">
          <div class="small-message" role="alert">
            <i class="fa fa-info-circle"></i>&nbsp;
            You should have received a message from the Telegram bot about the connection becoming
            successful. If you haven't, please try again.
          </div>

        </div>
      </div>
    </div>

    <!-- Connected -->
    <div class="row margin-top-element" v-if="telegramConnected">
      <div class="col">
        <div>
          <i class="fa fa-check-circle"></i>&nbsp;
          Your Telegram is account is connected to your trbo.io account.
        </div>
        <div>
          The {{telegramBot.name}} bot has the username:
          <strong>@{{telegramBot.username}}</strong>
        </div>
        <div>
          <a :href="telegramBotUrl" target="_blank"
             class="btn btn-primary btn-sm margin-top-element">
            <i class="fab fa-telegram"></i>&nbsp;
            Chat with the trbo.io bot on Telegram
          </a>
        </div>
        <div v-if="!isTelegramDisconnecting">
          <small>
            <a class="hover-red" href="javascript:"
               @click="toggleTelegramDisconnection(true)">
              Disconnect Account from Telegram</a>
          </small>
        </div>
        <div v-if="isTelegramDisconnecting">
          <small v-if="!telegramDisconnectionInProgress">
            <a class="red" href="javascript:" @click="disconnectFromTelegram()">
              Click to confirm disconnecting from Telegram</a>
            &nbsp;|&nbsp;
            <a href="javascript:" @click="toggleTelegramDisconnection(false)">
              Cancel</a>
          </small>
          <small v-if="telegramDisconnectionInProgress">
            <i class="fas fa-spin fa-spinner-third"></i>&nbsp;Disconnecting your account...
          </small>
        </div>
      </div>
    </div>
  </div>

  <!-- Email vs Telegram Settings -->
  <div class="light-gray-rounded-box margin-top-section" v-if="telegramConnected">
    <div class="row">
      <div class="col-12">
        <div>
          <strong>Email vs Telegram Preferences</strong>
        </div>
        <div>
          Which do you prefer?
        </div>
        <div class="side-note">
          <div>
            <strong>Please note:</strong>
          </div>
          <div>
            These options are only for <strong>reminders</strong>.
            These settings do not affect your ability to send impromptu posts from either
            Email or Telegram.
          </div>
        </div>
        <div>
          <label>
            <input v-model="emailReminders" type="checkbox">&nbsp;
            Send me reminders on email.
          </label>
        </div>
        <div>
          <label>
            <input v-model="telegramReminders" type="checkbox">&nbsp;
            Send me reminders on Telegram.
          </label>
        </div>
        <button @click="confirmPreferencesUpdate()"
                class="btn btn-primary btn-sm">Save Preferences</button>

        <span v-if="preferencesUpdating" class="small" style="margin-left: 10px;">
          <i class="fas fa-spin fa-spinner-third"></i>
          Updating your preferences...
        </span>

        <div class="small margin-top-element" v-if="updatePreferencesConfirm">
          <a @click="updatePreferences();" href="javascript:">Click to update preferences</a>&nbsp;
          |&nbsp;
          <a class="red" href="javascript:" @click="cancelPreferencesUpdation();" >Cancel</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import userInfo from '@/app/user-info';
import auth from '@/app/auth';
import utils from '@/app/utils';
import Loader from '@/components/Loader.vue';

export default {
  emits: [
    'refresh_logged_in_user',
  ],
  components: {
    Loader,
  },
  props: ['userEmail', 'isTelegramSetup', 'userEmailReminderFlag', 'userTelegramReminderFlag'],
  computed: {
    telegramBot() {
      return {
        name: process.env.VUE_APP_TELEGRAM_BOT_NAME,
        username: process.env.VUE_APP_TELEGRAM_BOT_USERNAME,
      };
    },
    telegramConnectionString() {
      return [
        'https://telegram.me/',
        process.env.VUE_APP_TELEGRAM_BOT_USERNAME,
        '?start=',
        this.telegram_codes.guid_code,
      ].join('');
    },
    telegramBotUrl() {
      return [
        'https://telegram.me/',
        process.env.VUE_APP_TELEGRAM_BOT_USERNAME,
      ].join('');
    },
    telegramConnected() {
      return this.isTelegramSetup;
    },
  },
  data() {
    return {
      telegramProcessing: false,
      telegram_codes: {
        code: null,
        guid_code: null,
      },
      emailReminders: true,
      telegramReminders: false,
      telegramRequestLoading: false,
      isTelegramDisconnecting: false,
      telegramDisconnectionInProgress: false,
      telegramStepsCompletedFlag: false,
      updatePreferencesConfirm: false,
      preferencesUpdating: false,
    };
  },
  methods: {
    async startTelegramConnection() {
      this.telegramRequestLoading = true;
      await this.fetchTelegramCodes();
      this.telegramProcessing = true;
      this.telegramRequestLoading = false;
    },
    cancelTelegramConnection() {
      this.telegramProcessing = false;
    },
    async fetchTelegramCodes() {
      const codes = await userInfo.fetchTelegramConnectionCode(await auth.getUserIdToken());
      this.telegram_codes.code = codes.code;
      this.telegram_codes.guid_code = codes.guid_code;
    },
    toggleTelegramDisconnection(status) {
      this.isTelegramDisconnecting = status;
    },
    async disconnectFromTelegram() {
      this.telegramDisconnectionInProgress = true;
      try {
        await userInfo.disconnectFromTelegram(await auth.getUserIdToken());
        utils.showToastSuccess('Success',
          'Your Telegram account connection was removed from trbo.io');

        // Reload everything
        this.reloadUserDetails();
        this.emailReminders = true;
        this.isTelegramDisconnecting = false;
        this.telegramReminders = false;
        this.telegramProcessing = false;
        this.telegramStepsCompletedFlag = false;

        // Wait for a bit to hide this
        setTimeout(() => {
          this.telegramDisconnectionInProgress = false;
        }, 500);
      } catch {
        this.telegramDisconnectionInProgress = false;
        utils.showToastError('Could not disconnect',
          'An error occurred and we could not disconnect your Telegram account from trbo. <br />'
            + 'Please refresh the page and try again.', true);
      }
    },
    reloadUserDetails() {
      this.$emit('refresh_logged_in_user');
    },
    telegramStepsCompleted() {
      this.reloadUserDetails();
      setTimeout(() => {
        this.telegramStepsCompletedFlag = true;
      }, 500);
    },
    confirmPreferencesUpdate() {
      this.updatePreferencesConfirm = true;
    },
    cancelPreferencesUpdation() {
      this.updatePreferencesConfirm = false;
    },
    async updatePreferences() {
      this.preferencesUpdating = true;
      this.updatePreferencesConfirm = false;

      try {
        await userInfo.updateUserReminderPreferences(
          await auth.getUserIdToken(),
          this.emailReminders,
          this.telegramReminders,
        );

        utils.showToastSuccess('Success',
          'Your preferences were saved successfully.');

        // Let the parent know that they need to update user settings
        this.reloadUserDetails();
        this.preferencesUpdating = false;
      } catch {
        utils.showToastError('Could not update Preferences',
          'An error occurred and we could not update your reminder preferences for you. <br />'
            + 'Please refresh the page and try again.', true);
        this.preferencesUpdating = false;
      }
    },
  },
  mounted() {
    this.emailReminders = this.userEmailReminderFlag;
    this.telegramReminders = this.userTelegramReminderFlag;
  },
};

</script>
