<template>
  <div class="row time-slot">
  <div class="col-12 clearfix">
    <div class="day-and-time">
      {{ day_of_week }}s at {{ time_of_email }}
    </div>
    <div class="time-slot-delete-button">
      <div v-show="!deleteMode">
        <a href="javascript:" @click="setDeleteMode();" class="red delete-time-slot">
          <i class="fa fa-trash"></i>
        </a>
      </div>
      <div v-if="deleteMode">
        <div v-if="!isDeleting">
          <a href="javascript:" @click="clearDeleteMode()">Cancel</a>&nbsp;|
          <a class="red" href="javascript:" @click="deleteSchedule()">
            Click to confirm deletion of this schedule</a>&nbsp;
        </div>
        <div v-if="isDeleting">
          <i class="fas fa-spin fa-spinner-third"></i> Deleting schedule...
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import auth from '@/app/auth';
import schedule from '@/app/schedule';
import utils from '@/app/utils';

export default {
  emits: ['schedule_deleted'],
  props: ['schedule_id', 'day_of_week', 'time_of_email'],
  data() {
    return {
      deleteMode: false,
      isDeleting: false,
    };
  },
  methods: {
    setDeleteMode() {
      this.deleteMode = true;
    },
    clearDeleteMode() {
      this.deleteMode = false;
    },
    async deleteSchedule() {
      this.isDeleting = true;

      try {
        await schedule.deleteSchedule(await auth.getUserIdToken(), this.schedule_id);
        this.isDeleting = false;
        utils.showToastSuccess('Schedule deleted',
          'The schedule was deleted successfully.');
        this.$emit('schedule_deleted', this.schedule_id);
      } catch {
        this.isDeleting = false;
        utils.showToastError('Schedule not deleted',
          'An error occurred due to which the schedule could not be deleted. Please '
            + 'refresh the page and try again.');
      }
    },
  },
};

</script>
