<template>
  <div class="journal-entry-box" v-if="!journalDeleted">
    <div v-if="!isInEditMode">
      <div class="journal-name">{{journalName}}</div>
      <div class="journal-code">@{{journalCode}}</div>
      <div class="journal-operations" v-if="!isInDeleteMode">
        <a href="javascript:" @click="isInEditMode = true;" >Edit</a>
        &nbsp;|&nbsp;
        <a class="red" @click="isInDeleteMode = true;" href="javascript:">Delete</a>
      </div>
    </div>
    <div v-if="isInEditMode">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <input class="form-control form-control-sm" v-model="journalName"
                 placeholder="Journal Name" />
        </div>
        <div class="col-md-3 col-sm-12 mt-2 mt-md-0">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text">@</span>
            </div>
            <input type="text" class="form-control" v-model="journalCode" @keydown.space.prevent />
          </div>
        </div>
        <div class="col-md-3 col-sm-12 mt-2 mt-md-0">
          <button class="btn btn-primary btn-sm"
                  @click="updateJournal();"
                  style="width: 48%;">Save</button>&nbsp;
          <button class="btn btn-secondary btn-sm"
                  @click="cancelSave();"
                  style="width: 48%;">Cancel</button>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <div class="side-note">
            <div>
              <i class="fa fa-exclamation-triangle"></i>&nbsp;
              <strong>Please note:</strong>
            </div>
            <div>
              If you are changing the <code>code</code> of the journal, please make sure to use the
              new code once you save the changes. The old code will stop working.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isInDeleteMode">
      <div class="row mt-2">
        <div class="col-12">
          <div class="side-note">
            <div>
              <i class="fa fa-exclamation-triangle"></i>&nbsp;
              <strong>Please note</strong>
            </div>
            <div>
              Deleting this journal <strong>will not</strong> delete the entries in this journal.
              They will continue to be available on your wall.
            </div>
            <div class="my-2">
              This cannot be undone. Are you sure?
            </div>
            <div class="mt-2">
              <button class="btn btn-sm btn-danger" @click="deleteJournal();">
                Delete this Journal</button>
              &nbsp;
              <button class="btn btn-sm btn-secondary" @click="cancelDelete();">
                Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isInProcess" class="progress-line blue-bar"></div>
  </div>
</template>

<script>
import userInfo from '@/app/user-info';
import utils from '@/app/utils';
import auth from '@/app/auth';

export default {
  props: ['journal', 'allJournals'],
  emits: ['journal_deleted'],
  data() {
    return {
      isInEditMode: false,
      isInDeleteMode: false,
      journalName: null,
      journalCode: null,
      isInProcess: false,
      journalDeleted: false,
    };
  },
  mounted() {
    this.journalName = this.journal.name;
    this.journalCode = this.journal.tag;
  },
  methods: {
    cancelSave() {
      this.journalName = this.journal.name;
      this.journalCode = this.journal.code;
      this.isInEditMode = false;
    },
    async updateJournal() {
      // Validation
      if (this.journalName.trim() === '' || this.journalCode.trim() === '') {
        utils.showToastError('Invalid details',
          'Please ensure you are specifying valid details for Journal names and code.',
          true);
      }

      // Check if a journal exists with the same name or tag
      if (this.allJournals != null && this.allJournals.length > 0) {
        let errorMessage = null;
        for (let i = 0; i < this.allJournals.length; i += 1) {
          const j = this.allJournals[i];
          if (j.name === this.journalName.trim() && j.id !== this.journal.id) {
            errorMessage = 'A journal with the same name already exists. Please choose a different name.';
          }

          if (j.tag.toLowerCase() === this.journalCode.trim().toLowerCase()
              && j.id !== this.journal.id) {
            errorMessage = 'A journal with the same code already exists. Please choose a different code.';
          }
        }

        if (errorMessage != null) {
          utils.showToastError('Duplicate entry detected.', errorMessage, true);
          return;
        }
      }

      try {
        this.isInProcess = true;
        await userInfo.updateJournal(await auth.getUserIdToken(), this.journal.id,
          this.journalName, this.journalCode);

        this.isInProcess = false;
        this.isInEditMode = false;

        utils.showToastSuccess('Update successful!',
          'The journal details were updated successfully.', false);

        this.isInEditMode = false;
      } catch (error) {
        this.isInProcess = false;
        utils.showToastError('Journal not updated.',
          'An unspecified error occurred and I could not update the journal details for you.'
            + ' Please refresh the page and try again.', true);
      }
    },
    cancelDelete() {
      this.isInDeleteMode = false;
    },
    async deleteJournal() {
      try {
        this.isInProcess = true;
        await userInfo.deleteJournal(await auth.getUserIdToken(), this.journal.id);

        this.isInProcess = false;
        utils.showToastSuccess('Journal deleted!',
          'The journal was deleted successfully.', false);

        this.$emit('journal_deleted', this.journal.id);

        this.isInDeleteMode = false;
        this.journalDeleted = true;
      } catch (error) {
        this.isInProcess = false;
        utils.showToastError('Journal not deleted.',
          'An unspecified error occurred and I could not delete this journal for you.'
            + ' Please refresh the page and try again.', true);
      }
    },
  },
};
</script>
