import utils from '@/app/utils';
import axios from 'axios';

const BASE_URL = `${process.env.VUE_APP_API_URL}/user/schedules`;

/** *
 * Fetches all the schedules for the logged in user.
 * @param token {string}
 */
const fetchAllSchedules = async (token) => {
  const resp = axios.get(BASE_URL, utils.getAxiosConfig(token));
  return (await resp).data;
};

/** *
 * Saves a schedule entry. If id is null, will create a new schedule entry.
 * @param token
 * @param id
 * @param dayOfWeek
 * @param timeOfDay
 * @return {Promise<void>}
 */
const saveSchedule = async (token, id, dayOfWeek, timeOfDay) => {
  const data = {
    day_of_week: dayOfWeek,
    time_of_email: timeOfDay,
  };

  const url = id ? `${BASE_URL}/${id}` : BASE_URL;

  let resp = null;
  if (id) {
    // Update
    resp = axios.patch(url, data, utils.getAxiosConfig(token));
  } else {
    // Create a new entry
    resp = axios.post(url, data, utils.getAxiosConfig(token));
  }

  return (await resp).data;
};

/** *
 * Deletes the specified schedule from the server
 *
 * @param token {string}
 * @param id {string}
 */
const deleteSchedule = async (token, id) => {
  const url = `${BASE_URL}/${id}`;

  await axios.delete(url, utils.getAxiosConfig(token));
  return true;
};

/** *
 * Create batch schedules at the same time for multiple days of the week
 * @param token
 * @param daysOfWeek
 * @param timeOfDay
 * @return {Promise<*>}
 */
const createBatchSchedules = async (token, daysOfWeek, timeOfDay) => {
  const url = `${BASE_URL}/batch`;
  const data = {
    days_of_week: daysOfWeek,
    time_of_email: timeOfDay,
  };

  const resp = await axios.post(url, data, utils.getAxiosConfig(token));
  return (await resp).data;
};

export default {
  fetchAllSchedules,
  saveSchedule,
  deleteSchedule,
  createBatchSchedules,
};
