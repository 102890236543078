<template>
  <div class="light-gray-rounded-box">
    <div class="row">
      <div class="col-md-12">
        <strong>Create a new Journal</strong>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6 col-sm-12">
        <label for="txtJournalName" class="form-label">Journal Name</label>
        <input type="text" class="form-control form-control-sm"
               v-model="journalName" id="txtJournalName" />
      </div>
      <div class="col-md-3 col-sm-12 mt-3 mt-md-0">
        <label for="txtJournalTag" class="form-label">
          Code
          <span class="small">
            ( <a href="#journalCodeHelp" data-toggle="modal">What's this?</a> )</span>
        </label>
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">@</span>
          </div>
          <input type="text" class="form-control"
                 v-model="journalCode" id="txtJournalTag" @keydown.space.prevent />
        </div>
      </div>
      <div class="col-md-3 col-sm-12 d-flex mt-3 mt-md-0">
        <button @click="createNewJournal();"
            style="margin-bottom: 1px;"
            class="btn btn-primary btn-sm mt-auto wide">Create Journal</button>
      </div>
    </div>
    <div class="progress-line blue-bar mt-2" v-if="isProcessing"></div>
  </div>

  <div class="light-gray-rounded-box mt-4">
    <div class="row">
      <div class="col-12">
        <strong>Your existing Journals</strong>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div v-if="existingJournals.length > 0">
          <journal-entry-settings v-for="journal in existingJournals"
                                  :key="journal"
                                  :all-journals="user.journal"
                                  @journal_deleted="onJournalDeleted"
                                  :journal="journal">
          </journal-entry-settings>
        </div>
        <div v-if="existingJournals.length === 0">
          You have not created any journals as yet.
        </div>
      </div>
    </div>

  </div>

  <!-- Modal for What's This -->
  <div class="modal fade" id="journalCodeHelp" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Journal Code</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>
            A <strong>Journal Code</strong> is a single word which helps identify a specific
            journal when you write entries from email.
          </p>
          <p>
            When you are journaling by email, you can start the entry with the specified code which
            trbo will use to add the entry to the correct journal.
          </p>
          <p>
            For example: <br />
            <code>@gratitude</code> or <code>@my_cool_project</code>
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JournalEntrySettings from '@/components/Setting/JournalEntrySettings.vue';
import userInfo from '@/app/user-info';
import auth from '@/app/auth';
import utils from '@/app/utils';
import _ from 'lodash';

export default {
  components: { JournalEntrySettings },
  props: ['user'],
  data() {
    return {
      existingJournals: [],
      journalName: '',
      journalCode: '',
      isProcessing: false,
    };
  },
  methods: {
    async createNewJournal() {
      if (this.journalName.trim() === '' || this.journalCode.trim() === '') {
        utils.showToastError('Invalid details',
          'Please ensure you are specifying valid details for Journal names and code.',
          true);
        return;
      }

      // Check if a journal exists with the same name or tag
      if (this.existingJournals != null && this.existingJournals.length > 0) {
        let errorMessage = null;
        for (let i = 0; i < this.existingJournals.length; i += 1) {
          const j = this.existingJournals[i];
          if (j.name === this.journalName.trim()) {
            errorMessage = 'A journal with the same name already exists. Please choose a different name.';
          }

          if (j.tag.toLowerCase() === this.journalCode.trim().toLowerCase()) {
            errorMessage = 'A journal with the same code already exists. Please choose a different code.';
          }
        }

        if (errorMessage != null) {
          utils.showToastError('Duplicate entry detected.', errorMessage, true);
          return;
        }
      }

      try {
        this.isProcessing = true;
        const newJournal = await userInfo.createNewJournal(await auth.getUserIdToken(),
          this.journalName, this.journalCode);
        this.isProcessing = false;

        if (newJournal != null) {
          this.existingJournals.push(newJournal);
          utils.showToastSuccess('Success!',
            'The journal was created successfully.', false);

          this.journalName = '';
          this.journalCode = '';
        } else {
          utils.showToastError('Journal not created.',
            'An unspecified error occurred and I was unable to create the journal for you.'
              + ' Please refresh the page and try again.', true);
        }
      } catch (error) {
        this.isProcessing = false;
        utils.showToastError('Journal not created.',
          'An unspecified error occurred and I was unable to create the journal for you.'
            + ' Please refresh the page and try again.', true);
      }
    },
    async onJournalDeleted(journalId) {
      _.remove(this.existingJournals, (x) => x.id === journalId);
    },
  },
  mounted() {
    if (this.user != null) {
      this.existingJournals = this.user.journal == null ? [] : this.user.journal;
    }
  },
};
</script>
